/**
 * Type: ページ
 * What: 王貞治ベースボールミュージアム Supported by DREAM ORDER
 */
import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../../components/Layout';
import SEO from '../../../components/Seo';
import BreadcrumbComponent from '../../../components/Breadcrumb';
import SimpleSwiper from '../../../components/Slider.fade';
import Image from '../../../util/Image';
import PostListComponent from '../../../components/PostList/PostList.obm';
// import NavObm from '../../../components/NavObm';

import useMediaQuery from '../../../util/useMediaQuery';

const mq = useMediaQuery;

const pageTitle = '王貞治ベースボールミュージアム Supported by DREAM ORDER';
const pageDescription = '野球人・王貞治の球史を振り返る展示品や映像を最新の手法で表現。\n'
  + ' 王貞治の波乱万丈の道のりを描きます。';
const pageSlug = 'obm';
const pageLogo = `logo-${pageSlug}.png`;

const sliderDataobj = [
  {
    filename: 'main-history.jpg',
  },
];

// Data Props Template
const Template = () => (
  // const scrollToTarget = (elem, offset) => {
  //   const $offset = offset || -110;
  //   scroller.scrollTo(elem, {
  //     duration: 1200,
  //     delay: 0,
  //     offset: $offset,
  //     smooth: 'easeOutQuint',
  //   });
  // };
  <Layout pageSlug={pageSlug} bg="bg-4">
    <SEO
      title={pageTitle}
      description={pageDescription}
    />
    <BreadcrumbComponent
      hierarchy={3}
      bread2url="obm/"
      bread2title="王貞治ベースボールミュージアム Supported by DREAM ORDER・89パーク・スタジオ"
      bread3title={pageTitle}
    />
    <section className="pageMain bg-12 bs-4">
      <div className="kv-wrap bg-4">
        <div className="kv-inner">
          <SimpleSwiper data={sliderDataobj} />
        </div>
        <div className="container">
          <div className="inner">
            <div className="pageMain-header">
              <div className="floorNo c-4">
                <span>4</span>
                <span>F</span>
              </div>
              <h3 className="ttl">
                <Image src={pageLogo} alt={pageTitle} />
              </h3>
            </div>
          </div>
        </div>
      </div>
      {/*
      <NavObm />
      */}
    </section>
    <PostListComponent />
    <section className="section sc-museum bg-12 bs-4" id="museum">
      <div className="container">
        <div className="inner">
          <h3 className="headline">
            <span>History Zone</span>
          </h3>
          <p className="text1 mb40 has-text-align-center">
            野球人・王貞治の球史を振り返る展示品や映像を最新の手法で表現。王さんの波乱万丈の道のりを描きます。
          </p>
          <div className="sc-columns">
            <div className="col col-6 floorbc4">
              <Link
                to="/obm/museum/player/"
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                <div className="col-inner">
                  <div className="col-image">
                    <figure className="image">
                      <Image filename="img-obm01.png" />
                    </figure>

                  </div>
                  <div className="col-content">
                    <h3 className="ttl c-4 has-text-align-center">幼少〜選手時代</h3>
                    {mq('sp') ? (
                      <div className="btn-group">
                        <p className="btn-def no-radius full-left">
                          <span>詳細を見る</span>
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Link>
            </div>
            <div className="col col-6 floorbc4">
              <Link
                to="/obm/museum/theater/"
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                <div className="col-inner">
                  <div className="col-image">
                    <figure className="image">
                      <Image filename="homerun-thater.jpg" />
                    </figure>

                  </div>
                  <div className="col-content">
                    <h3 className="ttl c-4 has-text-align-center">ホームランシアター</h3>
                    {mq('sp') ? (
                      <div className="btn-group">
                        <p className="btn-def no-radius full-left">
                          <span>詳細を見る</span>
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Link>
            </div>
            <div className="col col-6 floorbc4">
              <Link
                to="/obm/museum/manager/"
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                <div className="col-inner">
                  <div className="col-image">
                    <figure className="image">
                      <Image filename="img-obm03.png" />
                    </figure>

                  </div>
                  <div className="col-content">
                    <h3 className="ttl c-4 has-text-align-center">監督時代</h3>
                    {mq('sp') ? (
                      <div className="btn-group">
                        <p className="btn-def no-radius full-left">
                          <span>詳細を見る</span>
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Link>
            </div>
            <div className="col col-6 floorbc4">
              <Link
                to="/obm/museum/future/"
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                <div className="col-inner">
                  <div className="col-image">
                    <figure className="image">
                      <Image filename="img-obm04.png" />
                    </figure>

                  </div>
                  <div className="col-content">
                    <h3 className="ttl c-4 has-text-align-center">未来へ受け継ぐ王イズム</h3>
                    {mq('sp') ? (
                      <div className="btn-group">
                        <p className="btn-def no-radius full-left">
                          <span>詳細を見る</span>
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section sc-museum bg-4" id="specialexhibition">
      <div className="container">
        <h3 className="title is-3 has-text-align-center">Special exhibition</h3>
        <div className="tac">
          <Link
            to="/obm/museum/eventspace/"
            data-sal="slide-up"
            data-sal-easing="ease-out-expo"
            className="poster"
          >
            <Image src="bnr-obm02.jpg" />
          </Link>
        </div>
      </div>
    </section>
    <section className="section sc-museum bg-12">
      <div className="container">
        <div className="sc-columns">
          <div className="col col-6 floorbc4">
            <Link
              to="/"
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
              className="disable"
            >
              <div className="col-inner">
                <div>
                  <figure className="image">
                    <Image filename="workshop-room.jpg" />
                  </figure>
                </div>
                <div className="col-content">
                  <h3 className="ttl mb5">ワークショップルーム</h3>
                  <p className="text">
                    野球に関することはもちろんその他様々なワークショップのイベントを開催予定です。
                    <br />
                    広さ：50㎡ 定員40名程度
                  </p>
                  {/* mq('sp') ? (
                    <div className="btn-group">
                      <p className="btn-def no-radius full-left">
                        <span>詳細を見る</span>
                      </p>
                    </div>
                  ) : null */}
                </div>
              </div>
            </Link>
          </div>
          {/*
            <div className="col col-6 floorbc4">
              <Link
                to="/obm/museum/app/"
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                <div className="col-inner">
                  <div>
                    <figure className="image">
                      <Image filename="main-obm.jpg" />
                    </figure>
                  </div>
                  <div className="col-content">
                    <h3 className="ttl mb5">ガイドアプリ</h3>
                    <p className="text">
                      包み込むような３面の大型モニターで王さんの868号までの軌跡を臨場感たっぷりに見て頂けます。
                      <br />
                      ※修学旅行などの教育プログラム向けの映像も別途ご準備しております。
                      <br />
                      広さ：58.9㎡ 定員：40名程度
                    </p>
                    {mq('sp') ? (
                      <div className="btn-group">
                        <p className="btn-def no-radius full-left">
                          <span>詳細を見る</span>
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Link>
            </div>
            */}
        </div>
      </div>
    </section>
  </Layout>
);

export default Template;
